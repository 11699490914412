import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <p>
          No blog posts found.
        </p>
      </Layout>
    )
  }

  const [featuredPost, ...postList] = posts;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <div className="post-list-heading">
        <div className="post-list-heading-content">
          <h3>IRC News</h3>
          {/*<span>Everything about insurance in 2021</span>*/}
        </div>
      </div>
      <div className="featured-post-wrapper">
        <div className="featured-post-details">
          <h3>{ featuredPost.frontmatter.title || featuredPost.fields.slug }</h3>
          <span>{ featuredPost.frontmatter.description || featuredPost.excerpt }</span>
          <div>
            <Link to={`${featuredPost.fields.slug}`} itemProp="url">
              <small>Read More</small>
            </Link>
            <small>{featuredPost.frontmatter.date}</small>
          </div>
        </div>
        <div className="featured-post-thumbnail">
          <img src={featuredPost.frontmatter.thumbnail} alt=""/>
        </div>
      </div>
      <ol className="post-list-wrapper">
        {
          postList.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li key={post.fields.slug} className="post-list-item-wrapper">
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <div className="post-list-item-image">
                    <img src={post.frontmatter.thumbnail} alt=""/>
                  </div>
                  <div className="post-list-item-content">
                    <header>
                      <h2>
                        <Link to={`blog${post.fields.slug}`} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link>
                      </h2>
                    </header>
                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                    </section>
                    <Link to={`${post.fields.slug}`} itemProp="url">
                      <small className="post-list-item-anchor">Read More</small>
                    </Link>
                    <small className="post-list-item-date">{post.frontmatter.date}</small>
                  </div>
                </article>
              </li>
            )
          })
        }
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          thumbnail
        }
      }
    }
  }
`
